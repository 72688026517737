<template>
  <v-app>
    <navigation-bar :color="color" :flat="flat" />
    <v-main class="pt-20">
      <alert-component />
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <router-view></router-view>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="svg-border-waves text-white">
        <v-img src="@/assets/img/borderWavesBlue.svg" />
      </div>
     

    </v-main>
    <v-scale-transition>
      <v-btn fab v-show="fab" v-scroll="onScroll" dark fixed bottom right color="secondary" @click="toTop">
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
  </v-app>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
import { mapActions } from 'vuex';
import AlertComponent from '@/components/AlertComponent.vue';

export default {
  components: { NavigationBar, AlertComponent },

  data: () => ({
    fab: null,
    color: "",
    flat: null,
  }),

  // created() {
  //   const top = window.pageYOffset || 0;
  //   if (top <= 60) {
  //     this.color = "transparent";
  //     this.flat = true;
  //   }
  // },

  mounted() {
    this.fetchUser();
  },

  methods: {
    ...mapActions('authen', ['fetchUser']),

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },


  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        // this.color = "transparent";
        // this.flat = true;
      }
    },
  },



}
</script>

<style scoped>
.v-main {
  background-image: url("~@/assets/img/bgMain.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}
</style>