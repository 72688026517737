import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'leaflet/dist/leaflet.css';
import VueGeolocation from 'vue-browser-geolocation'
import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';
import api from '@/services/url-address'

const socket = io(api.mesURL);

Vue.use(VueSocketIOExt, socket);
Vue.config.productionTip = false
Vue.use(VueGeolocation)

new Vue({
  router,
  store,
  vuetify,   
  render: h => h(App)
}).$mount('#app')
