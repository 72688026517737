import dataService from "@/services/dataService";

const member = {
    namespaced: true,
    state: {
        data: []
    },
    mutations: {
        LIST_DATA(state, payload) {
            payload ? state.data = payload : ''
         },
        
    },
    actions: {
        async get({dispatch}, id) {
            try {
                if (!id) throw "ID not found" 
                const result = await dataService.member.get(id)

                return result
            } catch (e) {
                dispatch('utils/alertError', { message: e.message }, { root: true })
            }
        },

        async getByEmail(_, email) {
            
            const result = await dataService.member.getByEmail(email)
            return result.data
        },

        async list({ dispatch, commit }) {

            try {
                const result = await dataService.member.list();
                commit('LIST_DATA', result.data)

            } catch (e) {

                dispatch('utils/alertError', { message: e.message }, { root: true })
            }

        }

    }

}

export {member}