import dataService from "@/services/dataService"

const coordinates = {
    namespaced: true,
    state: {  
        data: [],   
    },
    getters: {
    },
    mutations: {  
        LIST_DATA(state, payload) {
            payload ? state.data = payload : ''
         },   
         
         UPDATE_DATA(state, payload) {
            
            const newData = state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.data = newData
            
        },

         DEL_DATA(state, payload) {
            state.data = state.data.filter(obj => obj.id !== payload)
        },
    },
    actions: {
        async create({ dispatch, rootState }, payload) {

            try {
                payload.updatedBy = rootState.authen.user.email
                
                const result = await dataService.coordinates.create(payload)
                                                           
                return result.data

            } catch (e) {
                dispatch('utils/alertError', { message: e.message }, { root: true })
            }
        },
    }
}

export {coordinates}