import dataService from "@/services/dataService";

const project = {
    namespaced: true,
    state: {
        data: []
    },
    mutations: {
        LIST_DATA(state, payload) {
            payload ? state.data = payload : ''
         },
        
    },
    actions: {
        
        async list({ dispatch, commit }) {

            try {
                const result = await dataService.project.list();
            
                commit('LIST_DATA', result.data)

            } catch (e) {

                dispatch('utils/alertError', { message: e.message }, { root: true })
            }
        }
    }

}


export { project }