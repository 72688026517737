import dataService from "@/services/dataService";

const machine = {
    namespaced: true,
    state: {
        data: []
    },
    mutations: {
        LIST_DATA(state, payload) {
            payload ? state.data = payload : ''
        },

    },
    actions: {

        async machineList({ dispatch }, projectId) {

            try {
                const result = await dataService.machine.machineList(projectId);
                
                return result.data
            } catch (e) {

                dispatch('utils/alertError', { message: e.message }, { root: true })
            }
        }

    }

}

export { machine }