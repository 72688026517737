import dataService from "@/services/dataService"

const booking = {
    namespaced: true,
    state: {
        data: [],
        personalBookingData: [],
    },
    getters: {
    },
    mutations: {
        LIST_DATA(state, payload) {
            payload ? state.data = payload : ''
        },
        LIST_PERSONAL_BOOKING_DATA(state, payload) {
            payload ? state.personalBookingData = payload : ''
        },

        UPDATE_DATA(state, payload) {

            const newData = state.data.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.data = newData
        },

        UPDATE_PERSONAL_BOOKING_DATA(state, payload) {

            const newData = state.personalBookingData.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.personalBookingData = newData

        },

        DEL_DATA(state, payload) {

            state.data = state.data.filter(obj => obj.id !== payload)
        },

        ADD_DATA(state, payload) {
            const stateId = state.data.map(obj => obj.id)
            const differance = payload.filter(obj => !stateId.includes(obj.id))

            state.data = [...state.data, ...differance]
        },

        REPLACE_DATA(state, payload) {
            
            state.data = Object.assign(state.data, payload)
        }
    },
    actions: {

        async create({ dispatch, rootState }, payload) {

            try {
                payload.updatedBy = rootState.authen.user.email


                const result = await dataService.booking.create(payload)

                dispatch('utils/alertAdded', null, { root: true })

                return result.data

            } catch (e) {
                dispatch('utils/alertError', { message: e.message }, { root: true })
            }
        },

        async update({ dispatch, commit, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email
                await dataService.booking.update(payload.id, payload)
                
                commit('UPDATE_DATA', payload)
                commit('UPDATE_PERSONAL_BOOKING_DATA', payload)
                dispatch('utils/alertUpdated', null, { root: true })

                return
            } catch (e) {
                console.log(e)
                dispatch('utils/alertError', { message: e.message }, { root: true })

            }
        },



        async destroy({ dispatch, commit }, id) {
            try {
                await dataService.booking.destroy(id)
                commit('DEL_DATA', id)
                return 1
            } catch (e) {
                dispatch('utils/alertError', { message: e.message }, { root: true })
                return 0
            }
        },

        async listBetweenDate({ dispatch, commit }, payload) {
            try {

                const result = await dataService.booking.listBetweenDate(payload.startDate, payload.endDate, payload.projectId);
                commit('LIST_DATA', result.data)               

                return result.data
            } catch (e) {
                dispatch('utils/alertError', { message: e.message }, { root: true })
            }
        },

        async listUpdatedBy({ dispatch,commit }, email) {
            try {

                const result = await dataService.booking.listUpdatedBy(email);
                commit('LIST_PERSONAL_BOOKING_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/alertError', { message: e.message }, { root: true })
            }
        }




    }
}

export { booking }