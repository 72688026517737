<template>
    <div>
        <v-navigation-drawer v-model="drawer" app temporary dark src="@/assets/img/bgDrawer.jpg">
            <v-list>
                <v-list-item>
                    <v-list-item-avatar>
                        <img src="@/assets/img/logo.png" alt="Logo" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="title">ThaiDrill</v-list-item-title>
                        <v-list-item-subtitle>CAR BOOKING</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-divider />
            <v-list dense>
                <v-list-item v-for="([icon, text, link], i) in items" :key="i" link :to="link">
                    <v-list-item-icon class="justify-center">
                        <v-icon>{{ icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="subtitile-1">{{
                            text
                            }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click.prevent="$store.dispatch('authen/signout')">
                    <v-list-item-icon class="justify-center">
                        <v-icon>mdi-export</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="subtitile-1">
                            ออกจากระบบ
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

        </v-navigation-drawer>

        <v-app-bar app :color="color" :flat="flat" dark class="pl-2" :class="{ expand: flat }">
            <v-toolbar-title>
                <v-img src="@/assets/img/logo.png" max-width="100px" />
            </v-toolbar-title>
            <v-spacer />
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="mr-4" v-if="isXs" />
            <div v-else>

                <v-btn text :to="'/'"><v-icon size="medium" class="mt-1">mdi-home</v-icon><span
                        class="mr-2">เมนูหลัก</span></v-btn>
                <v-btn text :to="'/booking'"><v-icon size="medium" class="mt-1">mdi-car-select</v-icon><span
                        class="mr-2">จองรถ</span></v-btn>
                <v-btn text :to="'/card'"><v-icon size="medium" class="mt-1">mdi-card-account-details-outline</v-icon>
                    <span class="mr-2">บัตรจอง</span></v-btn>
                <v-btn text :to="'/Mreport'"><v-icon size="medium" class="mt-1">mdi-chart-box-outline</v-icon><span
                        class="mr-2">รายงาน</span></v-btn>
                <v-btn text @click.prevent="$store.dispatch('authen/signout')"><v-icon size="medium"
                        class="mt-1">mdi-export</v-icon><span class="mr-2">ออกจากระบบ</span></v-btn>
            </div>
        </v-app-bar>
    </div>
</template>

<script>
export default {
    data: () => ({
        drawer: null,
        isXs: false,
        items: [
            ["mdi-home-outline", "เมนูหลัก", "/"],
            ["mdi-car-select", "จองรถ", "/booking"],
            ["mdi-card-account-details-outline", "บัตรจอง", "/card"],
            ["mdi-chart-box-outline", "รายงาน", "/Mreport"],
        ],
    }),

    props: {
        color: String,
        flat: Boolean,
    },

    mounted() {
        this.onResize();
        window.addEventListener("resize", this.onResize, { passive: true });
    },

    methods: {
        onResize() {
            this.isXs = window.innerWidth < 850;
        }
    },

    watch: {
        isXs(value) {
            if (!value) {
                if (this.drawer) {
                    this.drawer = false;
                }
            }
        },
    },

}
</script>

<style scoped>
.v-toolbar {
    transition: 0.6s;
}

.expand {
    height: 80px !important;
    padding-top: 10px;
}
</style>