import { auth } from "@/firebase"
import { createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateProfile } from "firebase/auth"

const authen = {
    namespaced: true,
    state: {
        user: null,
    },
    getters: {},
    mutations: {
        SET_USER_AUTHENTICATION(state, payload) {
            state.user = payload

        },
    },
    actions: {
        async signin(_, payload) {

            const userCredential = signInWithEmailAndPassword(auth, payload.email, payload.password)

            return userCredential
        },

        async signout({ commit }) {

            await signOut(auth).then(() => {

                commit('SET_USER_AUTHENTICATION', null)
            })

        },

        setUserProfile({ commit }, userAuthen) {

            if (userAuthen.displayName && userAuthen.emailVerified) {

                let profile = JSON.parse(userAuthen.displayName)
                profile.emailVerified = userAuthen.emailVerified
                profile.uid = userAuthen.uid
                profile.employeeCode = profile.id
                profile.email = userAuthen.email

                commit('SET_USER_AUTHENTICATION', profile);
            }

        },

        fetchUser({ dispatch }) {

            auth.onAuthStateChanged(user => {

                if (user && user.emailVerified) {

                    dispatch('setUserProfile', user)
                    return
                } else {

                    dispatch('signout')
                }
            })
        },

        async createAuthenUser(_, profile) {
           
            const userCredential = await createUserWithEmailAndPassword(auth, profile.email, profile.password)

            return userCredential
        },

        async sendEmailVerify(_, userCredential) {
      
            sendEmailVerification(userCredential.user)
        },


        async updateProfileUser(_, profile) {
            const registerData = profile

            updateProfile(auth.currentUser, {
                displayName: JSON.stringify(registerData)
            })
        },

        async resetPassword({ dispatch }, email) {
            await sendPasswordResetEmail(auth, email)
                .then(() => {
                    dispatch('utils/alertSuccess', { message: 'ระบบทำการส่งลิงค์เปลี่ยน Password ไปยัง Email Address ของคุณแล้วค่ะ' }, { root: true })
                })
                .catch((error) => {
                    dispatch('utils/alertError', error, { root: true })
                })
        },

    }
}

export { authen }