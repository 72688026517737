import dataService from "@/services/dataService"

const notify = {
    namespaced: true,
    state: {       
    },
    getters: {
    },
    mutations: {},
    actions: {
        async sendMessage(_, message) {    
                  
           const result = await dataService.notify.sendMessage(message)

            return result.data
        },
    }
}

export { notify }