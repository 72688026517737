import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from "vuex-persist-indexeddb";
import { authen } from './modules/authen';
import { utils } from './modules/utils';
import { member } from './modules/member';
import { project } from './modules/project';
import { machine } from './modules/machine';
import { fileServer } from './modules/fileServer';
import { booking } from './modules/booking';
import { coordinates } from './modules/coordinates';
import { photo } from './modules/photo';
import { report } from './modules/report';
import { notify } from './modules/notify';

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    authen,
    utils,
    member,
    project,
    machine,
    fileServer,
    booking,
    coordinates,
    photo,
    report,
    notify
  }
})

export default store
