const utils = {
    namespaced: true,
    state: {
        editUser: null,
        alertToggle: null,
        alertProp: {type: '', message:''},
    },
    getters: {
    },
    mutations: {
        SET_EDIT_USER_DAILOG(state, payload) {
            state.editUser = payload
        },
        SET_ALERT_TOGGLE(state) {
            state.alertToggle = !state.alertToggle;       
        },
        SET_ALERT_PROP(state, payload) {
            state.alertProp = Object.assign(payload)
        }

    },
    actions: {
        alertError({commit},error) {
            const alertProp = {
                type: 'error',
                message: `${error.message}`
            }

            commit('utils/SET_ALERT_TOGGLE', null, { root: true })
            commit('utils/SET_ALERT_PROP', alertProp, { root: true })
        },

        alertWarning({commit},error) {
            const alertProp = {
                type: 'warning',
                message: `${error.message}`
            }

            commit('utils/SET_ALERT_TOGGLE', null, { root: true })
            commit('utils/SET_ALERT_PROP', alertProp, { root: true })
        },

        alertSuccess({commit},txtObj) {
            const alertProp = {
                type: 'success',
                message: `${txtObj.message}`
            }

            commit('utils/SET_ALERT_TOGGLE', null, { root: true })
            commit('utils/SET_ALERT_PROP', alertProp, { root: true })
        },
        
        alertUpdated({commit}) {
            const alertProp = {
                type: 'success',
                message: 'อัพเดตข้อมูลเรียบร้อยค่ะ'
            }

            commit('utils/SET_ALERT_TOGGLE', null, { root: true })
            commit('utils/SET_ALERT_PROP', alertProp, { root: true })
        },

        alertRegister({commit}) {           
            const alertProp = {
                type: 'info',
                message: 'ลงทะเบียนผู้ใช้เรียบร้อยค่ะ อยู่ระหว่างรอการยืนยันจากเจ้าหน้าที่'
            }

            commit('utils/SET_ALERT_TOGGLE', null, { root: true })
            commit('utils/SET_ALERT_PROP', alertProp, { root: true })
        },

        alertBooking({commit}) {           
            const alertProp = {
                type: 'info',
                message: 'บันทึกการจองเรียบร้อยค่ะ ขอบคุณค่ะ'
            }

            commit('utils/SET_ALERT_TOGGLE', null, { root: true })
            commit('utils/SET_ALERT_PROP', alertProp, { root: true })
        },

        alertDuplicateBooking({commit}) {           
            const alertProp = {
                type: 'error',
                message: 'รายการถูกจองไปแล้ว กรุณาตรวจสอบอีกครั้งค่ะ'
            }

            commit('utils/SET_ALERT_TOGGLE', null, { root: true })
            commit('utils/SET_ALERT_PROP', alertProp, { root: true })
        },

        alertAdded({commit}) {           
            const alertProp = {
                type: 'info',
                message: 'บันทึกข้อมูลเรียบร้อยค่ะ'
            }

            commit('utils/SET_ALERT_TOGGLE', null, { root: true })
            commit('utils/SET_ALERT_PROP', alertProp, { root: true })
        },

        notify({rootState}, payload) {
            
            let location = rootState.carBook.locationBooking
            
            let _date = new Date(payload.start).toLocaleDateString('th-TH',{month:'short',day:'numeric'})
            let _time = new Date(payload.start).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })
            let _data = {message: `${location.name} พบรายการจองใหม่ กรุณาตรวจสอบ ${payload.carId} ${_date} ${_time}`}
            fetch('https://lazy-gray-sea-lion-gown.cyclic.app', {
                method: "POST",
                body: JSON.stringify(_data),
                headers: {"Content-type": "application/json; charset=UTF-8"}
            })
        },

        notifyRegister(_, payload) {
            let _data = {message: `พบผู้ใช้ใหม่ กรุณาตรวจสอบ ${payload.employeeCode} ${payload.firstName} ${payload.lastName}`}
            fetch('https://lazy-gray-sea-lion-gown.cyclic.app', {
                method: "POST",
                body: JSON.stringify(_data),
                headers: {"Content-type": "application/json; charset=UTF-8"}
            })
        }      

    },
}

export { utils }