import { auth } from "@/firebase";
import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import api from "./url-address";

const instance = axios.create({    
    baseURL: api.mesURL,
    headers: {
        "Content-type": "application/json"
    }
})

onAuthStateChanged(auth, (user) => {
    if (user) {
        instance.defaults.headers.common['Authorization'] = `Bearer ${user.accessToken}`
    }
})




export default instance
