import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyBXpxmaKmfjzNVtgO7cZtCXmPPwt6gv2xM",
  authDomain: "tdusers-fbd24.firebaseapp.com",
  projectId: "tdusers-fbd24",
  storageBucket: "tdusers-fbd24.appspot.com",
  messagingSenderId: "747367905775",
  appId: "1:747367905775:web:4d9d4eaa7e36d89afb5e1a"
};


  // Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {auth}