import dataService from "@/services/dataService"

const fileServer = {
    namespaced: true,
    state: {     
    },
    getters: {
    },
    mutations: {      
    },
    actions: {

        async upload({dispatch}, payload) {

            try {                
                const result = await dataService.fileServer.upload(payload)              
                
                return result.data
            }catch (e) {
                dispatch('utils/alertError', { message: e.message }, { root: true })
               
            }
        },
     
        async download({ dispatch }, payload) {
            try {
                const result = await dataService.fileServer.download(payload)
                if (result.data.error) {
                    console.error(result.data.error)
                }
                
                return result.data
            } catch (e) {
                console.log(e)
                dispatch('utils/alertError', { message: e.message }, { root: true })
            }
        },

        async downloadImg({ dispatch }, payload) {
            try {
                const result = await dataService.fileServer.downloadImg(payload)
                if (result.data.error) {
                    console.error(result.data.error)
                }
                
                return result.data
            } catch (e) {
                console.log(e)
                dispatch('utils/alertError', { message: e.message }, { root: true })
            }
        }

    }
}

export { fileServer }