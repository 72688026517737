<template>
    <div class="alert-content">
        <v-alert dense v-model="alert" :type="types" transition="scale-transition">
            <v-row>
                <v-col class="grow">
                    {{ message }}
                </v-col>
                <v-col class="shrink">
                    <v-icon @click="closeAlert()" color="white">mdi-close-box-outline</v-icon>
                </v-col>
            </v-row>
        </v-alert>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
    data() {
        return {
            alert: null,
            types: null,
            message: "",
        };
    },
    methods: {
        ...mapMutations("utils", ["SET_ALERT_TOGGLE"]),

        closeAlert() {
            if (this.alertToggle === true) {
                this.SET_ALERT_TOGGLE();
            }
        },
    },
    computed: {
        ...mapState("utils", ["alertToggle", "alertProp"]),
    },
    watch: {
        alertToggle() {
            this.alert = this.alertToggle;
            this.types = this.alertProp.type;
            this.message = this.alertProp.message;

            if (this.alertToggle === true && this.types != 'error') {
                setTimeout(() => {
                    this.closeAlert();
                }, 3000);
            }
        },
    },

}
</script>

<style>
.alert-content {
  top: 7%;
  right: 20px;  
  z-index: 999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}

</style>