import dataService from "@/services/dataService"

const report = {
    namespaced: true,
    state: {
        data: [],    
    },
    getters: {
    },
    mutations: {
        LIST_DATA(state, payload) {
            payload ? state.data = payload : ''
        },      
      
    },
    actions: {
     
        async listBetweenDate({ dispatch, commit }, payload) {
            try {

                const result = await dataService.report.listBetweenDate(payload.startDate, payload.endDate);
                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/alertError', { message: e.message }, { root: true })
            }
        },
        async listBetweenDateCollectionPoint({ dispatch, commit }, payload) {
            try {
                const result = await dataService.report.listBetweenDateCollectionPoint(payload.startDate, payload.endDate);
                console.log(result.data)
                commit('LIST_DATA', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/alertError', { message: e.message }, { root: true })
            }
        },

        async listByDateNow({dispatch}) {
            try {

                const result = await dataService.report.listByDateNow();
                
                return result.data
            } catch (e) {
                dispatch('utils/alertError', { message: e.message }, { root: true })
            }

        }




    }
}

export { report }